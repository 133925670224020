import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { useAuth } from "../../context/auth";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    color: theme.palette.primary,
  },
  toolbarTitle: {
    fontFamily: 'Leckerli One, cursive',
    flex: 1,
    fontSize: '40px',
  },
  toolbarSecondary: {
    justifyContent: 'center',
    overflowX: 'auto',
    backgroundColor: theme.palette.divider,
    marginBottom: '20px',
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
  },
  dropdown: {
    color: theme.palette.primary,
  },
  root: {
    flexGrow: 1,
    marginBottom: theme.spacing(3),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const navigate = (path) => {
 window.location = path;
};

export default function Header(props) {
  const classes = useStyles();
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
  const [accountAnchorEl, setAccountAnchorEl] = React.useState(null);
  const { setAuthToken } = useAuth();

  const menuOpen = Boolean(menuAnchorEl);
  const accountOpen = Boolean(accountAnchorEl);

  const { categories = {}, title } = props;

  const categoriesData = categories.data || [];

  if (!categoriesData.length) {
    props.loadCategories();
  }

  const renderCategories = () => {
    return categoriesData.map((category, idx) => {
      return <MenuItem key={category._id} onClick={() => navigate(`/articles/${category.name}`)}>{category.display}</MenuItem>
    })
  };

  const { authTokens } = useAuth();

  const handleMenu = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleAccount = (event) => {
    setAccountAnchorEl(event.currentTarget);
  };

  const handleAccountClose = () => {
    setAccountAnchorEl(null);
  };

  const logout = () => {
    setAccountAnchorEl(null);
    setAuthToken({ token: null });
  }

  const handleAdd = () => {
    navigate('/admin/edit/add');
  }

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={handleMenu}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={menuAnchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={menuOpen}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={() => navigate('/')}>Home</MenuItem>
            <MenuItem onClick={() => navigate('/about')}>About</MenuItem>
            <Divider />
            {
              renderCategories()
            }
          </Menu>
          <Typography
            component="h2"
            variant="h5"
            color="inherit"
            align="center"
            noWrap
            className={classes.toolbarTitle}
          >
            Blogiply
          </Typography>
          {!!authTokens && (
            <div>
              <IconButton
               aria-label="add article"
               color="inherit"
               onClick={handleAdd}
              >
               <AddCircleIcon/>
              </IconButton>

              <IconButton
               aria-label="account of current user"
               aria-controls="menu-appbar"
               aria-haspopup="true"
               onClick={handleAccount}
               color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={accountAnchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={accountOpen}
                onClose={handleAccountClose}
              >
                <MenuItem onClick={logout}>Logout</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </div>
  )
}

Header.propTypes = {
  sections: PropTypes.array,
  title: PropTypes.string,
};

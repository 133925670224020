import 'whatwg-fetch';
import qs from 'qs';

const getCategories = async (options = {}) => {
  const { query } = options
  let categories = [];
  let params = '';
  let url = '/api/categories';
  if (options) {
    params = qs.stringify(options);
    url = `${url}?${params}`;
  }
  const response = await fetch(url)
  .then(function(response) {
    return response.json()
  });
  if (response.status !== 200) throw Error(response.message);
  if (response.response && response.response.results) {
    categories = response.response.results;
    return categories;
  } else {
    return response;
  }
}

export default {
  getCategories,
}

import React from 'react'
import { useAuth } from "../../context/auth";
import ArticleBody from './articleBody';
import qs from 'query-string';

const Article = props => {
  const { authTokens } = useAuth();
  const _id = props.match.params.id;
  const category = props.match.params.category;
  const queryStringObj = qs.parse(props.location.search);
  const paramImage = queryStringObj.image;
  const paramVideo = queryStringObj.video;

  if (!props.articles.init) {
    props.loadSingleArticle({ _id, category }).then(resp => {
      props.loadSingleAuthor({ _id: resp[0].author });
    });
    props.loadAuthors().then(() => {
      props.loadArticles({ query: { category }, isRecent: true });
    });
  }

  const article = props.articles.single || { images: [], videos: [] };
  const author = props.users.single || { first_name: '', last_name: ''};

  return <ArticleBody {...props} authTokens={authTokens} author={author} article={article} paramImage={paramImage} paramVideo={paramVideo} />
}

export default Article

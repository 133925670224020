import 'whatwg-fetch';

const generateCategoryRSSFeed = async (options) => {
  const { userId, category } = options
  let url = `/api/feeds/${userId}/${category}/generate`;

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'token': localStorage.getItem('token')
    },
    body: JSON.stringify(options),
  })
  .then(function(response) {
    return response.json()
  });
  return response;
}

export default {
  generateCategoryRSSFeed,
}

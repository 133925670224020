import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Header from '../components/header'
import { loadCategories } from '../actions/categories'

const mapStateToProps = state => {
  return {
    categories: state.categories,
  }
}

const mapDispatchToProps = dispatch => ({
  loadCategories: bindActionCreators(loadCategories, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header)

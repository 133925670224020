import categoryService from '../services/categoryService';

export const loadCategories = () => {
  return (dispatch) => {
    return categoryService.getCategories().then((data) => {
      dispatch({
        type: 'LOAD_CATEGORIES',
        data
      });
      return data;
    })
  }
}

export const showSnackbar = ({ message, variant }) => {
  return dispatch => {
    dispatch({ type: 'SNACKBAR_OPEN', message, variant });
  };
};

export const clearSnackbar = () => {
  return dispatch => {
    dispatch({ type: 'SNACKBAR_CLEAR' });
  };
};

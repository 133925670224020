import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PinterestIcon from '@material-ui/icons/Pinterest';
import EventIcon from '@material-ui/icons/Event';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  authorAvatar: {
    width: '100px',
    height: '100px',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  authorInfo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'no-wrap',
    flexDirection: 'column',
  },
  pinterestButton: {
    margin: theme.spacing(.5),
  },
  authorWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    borderRadius: '10px',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(4),
    backgroundColor: '#ededed',
  },
  divider: {
    width: '25%',
  },
  bio: {
    fontSize: '18px',
    textAlign: 'center',
    marginTop: theme.spacing(1),
  }
}));

const AuthorBio = (props) => {
  const { author = {} } = props;
  const classes = useStyles();
  return (
    <Grid>
      <div className={classes.authorWrapper}>
        <Typography component="h2" variant="h5">
          {`${author.first_name} ${author.last_name}`}
        </Typography>
        <Divider className={classes.divider} />
        <div className={classes.authorInfo}>
          {author.bio && <div className={classes.bio}>{author.bio}</div>}
        </div>
        <Avatar
          className={classes.authorAvatar}
          src={author.profile_pic}
          alt="thumbnail"
        />
        <div className={classes.authorInfo}>
          {author.location && <span><LocationOnIcon/>{author.location}</span>}
          {author.created_at && <span><EventIcon/>Joined: {moment(author.created_at).format('LL')}</span>}
          {author.pinterest_link &&
            <Link href={author.pinterest_link} rel="noreferrer noopener" target="_blank">
              <IconButton
                className={classes.pinterestButton}
                variant="contained"
                color="primary"
                size="small"
              >
                <PinterestIcon />
              </IconButton>
            </Link>
          }
        </div>
      </div>
    </Grid>
  )
};

export default AuthorBio;

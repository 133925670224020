import React from 'react'

const Disclaimer = () => (
  <div>
  <p>Every effort has been made to accurately represent this web site or product and its potential. Even though this industry is one of the few where one can write their own check in terms of earnings, there is no guarantee that you will earn any money using the techniques and ideas in these materials.</p>
  <p>Examples in these materials are not to be interpreted as a promise or guarantee of earnings. Earning potential is entirely dependent on the person using our product, ideas, techniques and the effort put forth. We do not purport this as a &#8220;get rich scheme&#8221;</p>
  <p>Your level of success in attaining the results claimed in our materials depends on the time you devote to the program, ideas and techniques mentioned, knowledge and various skills. Since these factors differ according to individuals, we cannot guarantee your success or income level.</p>
  <p>Any income claims or results for selling products on Amazon cannot be guaranteed. It is at the sole discretion of the user to put forth the effort needed. Due to the fact the provider of the materials cannot guarantee the effort of the user, success cannot be expressly guaranteed.</p>
  <p>Any success and income claims by advertising using Google Adsense cannot be guaranteed. The user must use the knowledge provided within the materials or have a working knowledge of other methods outside of the materials. Due to the lack of control the provider of the materials has in the user putting forth the effort, Adsense income claims are also not guaranteed.</p>
  <p>Any claims regarding affiliate income or success as an affiliate are also not guaranteed. The user of the materials is soley responsible for success or lack thereof after following any techniques that have to do with affiliate marketing. </p>
  <p>Materials in our product and our website may contain information that includes or is based upon forward-looking statements within the meaning of the securities litigation reform act of 1995. Forward-looking statements give our expectations or forecasts of future events.</p>
  <p>You can identify these statements by the fact that they do not relate strictly to historical or current facts. They use words such as &#8220;anticipate,&#8221; &#8220;estimate,&#8221; &#8220;expect,&#8221; &#8220;project,&#8221; &#8220;intend,&#8221; &#8220;believe,&#8221; &#8220;plan,&#8221; and other words and terms of similar meaning in connection with a description of potential earnings or financial performance.</p>
  <p>Any and all forward looking statements here or on any of our sales material are intended to express our opinion of earnings potential. Many factors will be important in determining your actual results and no guarantees are made that you will achieve results similar to ours or anybody elses, in fact no guarantees are made that you will achieve any results from our ideas and techniques.</p>
  <p>This site may contain affiliate links where the advertiser/creator earns a commission if you purchase the product. This site may have links to amazon products as an affiliate and/or may generate revenue if any visitor clicks on advertisements presented by Google Adsense or any other contextual advertising program. Affiliate links from sites like clickbank or shareasale or any other websites may be present and the site owner may earn commissions by recommending products on this website.</p>
  <p>Results vary, and as with any money-making opportunity, you could make more or less. Success in ANY money-making opportunity is a result of hard work, time and a variety of other factors. No express or implied guarantees of income are made by http://blogiply.com.</p>
  </div>
);

export default Disclaimer;

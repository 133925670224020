import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import ReactPlayer from 'react-player/lazy';
import {
  LazyLoadImage,
  LazyLoadComponent,
} from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { Media, Image } from 'react-bootstrap';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Container from '@material-ui/core/Container';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import Link from '@material-ui/core/Link';
import ImageModal from './ImageModal';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PinterestIcon from '@material-ui/icons/Pinterest';
import EventIcon from '@material-ui/icons/Event';
import { Helmet } from 'react-helmet';
import BannerAd from '../blog/BannerAd';
import LinkAds from '../blog/LinkAds';
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles((theme) => ({
  authorWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    maxHeight: '300px',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '10px',
    padding: theme.spacing(1),
  },
  authorInfo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'space-between',
    flexWrap: 'no-wrap',
    flexDirection: 'column',
    textAlign: 'center',
  },
  authorAvatar: {
    width: '100px',
    height: '100px',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  mainImage: {
    borderRadius: '10px',
  },
  photo: {
    width: '100%',
    borderRadius: '10px',
    marginTop: theme.spacing(1),
  },
  recentContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '10px',
    padding: theme.spacing(1),
  },
  recentWrapper: {
    display: 'flex',
    flexWrap: 'no-wrap',
    borderBottom: `1px solid ${theme.palette.divider}`,
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  recentPhoto: {
    maxHeight: '75px',
    borderRadius: '10px',
  },
  recentInfo: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '70%',
  },
  recentLine: {
    width: '100%',
  },
  gridListRoot: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(2),
    display: 'flex',
    flexWrap: 'nowrap',
    width: '100%',
  },
  gridListTile: {
    height: '300px !important',
    width: 'auto !important',
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  gridListImage: {
    cursor: 'pointer',
  },
  chipsWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    marginTop: theme.spacing(1),
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  edit: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  imageBackground: {
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    cursor: 'pointer',
  },
  videoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 'auto',
  },
  article: {
    width: '100%',
  },
  bannerSpacer: {
    height: '25px',
  },
  title: {
    marginBottom: '0',
  },
}));

const ArticleBody = (props) => {
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [modalImage, setModalImage] = React.useState('');
  const [modalPinTarget, setModalPinTarget] = React.useState('');

  const classes = useStyles();
  const { authTokens, article, author, paramImage, paramVideo } = props;
  const { body = '', videos = [] } = article;

  document.title = article.title;

  let mainImage = {
    Location: article.main_image,
    Key: article.main_image,
  };

  let foundImage = article.images.find(
    (image) => image.Location === article.main_image
  );
  if (foundImage) {
    mainImage = foundImage;
  }

  if (paramImage) {
    foundImage = article.images.find((image) => image.Key === paramImage);
    if (foundImage) {
      mainImage = foundImage;
    }
  }

  let foundVideo;
  if (paramVideo) {
    foundVideo = article.videos.find((video) => video.Key === paramVideo);
  }

  if (article.isHTMLBody) {
    article.body = body
      .replace(/<img/g, `<img class=${classes.photo}`)
      .replace(/ width=/g, ' max-width=')
      .replace(/ height=/g, ' max-height=');
  }

  const handleModalClose = () => {
    setModalIsOpen(false);
    setModalImage('');
    setModalPinTarget('');
  };

  const onImageClick = (image) => {
    setModalIsOpen(true);
    setModalImage(image.Location);
    setModalPinTarget(
      `blogiply.com/${article.category}/article/${article._id}?image=${image.Key}`
    );
  };

  return (
    <div>
      <Helmet>
        <title>{`Blogiply - ${article.title}`}</title>
        <meta property='og:title' content={`Blogiply - ${article.title}`} />
        <meta property='og:type' content='article' />
        <meta property='og:site_name' content='Blogiply' />
        <meta property='og:image' content={mainImage.Location} />
        <meta property='og:description' content={article.description} />
        <meta property='description' content={article.description} />
        <meta property='article:published_time' content={article.created_at} />
        <meta
          property='article:author'
          content={`${author.first_name} ${author.last_name}`}
        />
      </Helmet>
      <ImageModal
        handleModalClose={handleModalClose}
        isOpen={modalIsOpen}
        imageUrl={modalImage}
        pinTarget={modalPinTarget}
      />
      <Grid item container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          {!!authTokens && (
            <div className={classes.edit}>
              <a href={`/admin/edit/${article._id}`}>
                <IconButton aria-label='add article' color='primary'>
                  <EditIcon />
                </IconButton>
              </a>
            </div>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid item>
            <div>
              <BannerAd adKey={'banner1'} />
            </div>
          </Grid>
        </Grid>
        <Hidden smDown>
          <Grid
            item
            container
            direction='column'
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            spacing={3}
          >
            <Grid item>
              <div>
                <BannerAd adKey={'banner2'} />
                <div className={classes.bannerSpacer}></div>
                <BannerAd adKey={'banner3'} />
                <div className={classes.bannerSpacer}></div>
                <BannerAd adKey={'banner4'} />
              </div>
            </Grid>
          </Grid>
        </Hidden>
        <Grid
          container
          item
          direction='column'
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
        >
          {foundVideo ? (
            <ReactPlayer
              key={foundVideo.Key}
              url={foundVideo.Location}
              controls={true}
              className={classes.videoWrapper}
              width={'100%'}
              height={'auto'}
            />
          ) : (
            <LazyLoadComponent>
              <div
                style={{ backgroundImage: `url(${mainImage.Location})` }}
                className={classes.imageBackground}
                onClick={() => onImageClick(mainImage)}
              >
                <img
                  src={mainImage.Location}
                  alt={`${article.category}-${mainImage.Key}`}
                  style={{ visibility: 'hidden' }}
                  className={classes.imageBackground}
                />
              </div>
            </LazyLoadComponent>
          )}
          {article.tags && (
            <div className={classes.chipsWrapper}>
              {article.tags.map((tag) => (
                <Chip label={tag} key={tag} />
              ))}
            </div>
          )}
          <h1 className={classes.title}>{article.title}</h1>
          <h4>{moment(article.created_at).format('LL')}</h4>
          <div className={classes.article}>
            {article.isHTMLBody ? (
              <Typography component='div'>
                <div dangerouslySetInnerHTML={{ __html: article.body }} />
              </Typography>
            ) : (
              <Typography component='div'>
                <div>
                  {body.split('\n').map((par, idx) => (
                    <p key={idx}>{par}</p>
                  ))}
                </div>
              </Typography>
            )}
          </div>
          {!!article.videoUrl && (
            <LazyLoadComponent>
              <ReactPlayer
                url={article.videoUrl}
                controls={true}
                className={classes.videoWrapper}
                width={'100%'}
                height={'auto'}
              />
            </LazyLoadComponent>
          )}
          {videos.map((video) => (
            <LazyLoadComponent>
              <ReactPlayer
                key={video.Key}
                url={video.Location}
                controls={true}
                className={classes.videoWrapper}
                width={'100%'}
                height={'auto'}
              />
            </LazyLoadComponent>
          ))}
          <div className={classes.gridListRoot}>
            <GridList className={classes.gridList} cols={2.5}>
              {article.images.map((image) => (
                <GridListTile className={classes.gridListTile} key={image.Key}>
                  <LazyLoadImage
                    effect='blur'
                    height={300}
                    src={image.Location}
                    alt={`${article.title}-${article.category}-${image.Key}`}
                    wrapperClassName={classes.gridListImage}
                    onClick={() => onImageClick(image)}
                    data-pin-url={`blogiply.com/${article.category}/article/${article._id}?image=${image.Key}`}
                    data-pin-media={image.Location}
                    data-pin-description={article.description}
                  />
                </GridListTile>
              ))}
            </GridList>
          </div>
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={3}
          lg={3}
          xl={3}
        >
          <Grid item>
            <div className={classes.authorWrapper}>
              <Typography component='h2' variant='h5'>
                About Author
                <Divider />
              </Typography>
              <Avatar
                className={classes.authorAvatar}
                src={author.profile_pic}
                alt='thumbnail'
              />
              <div className={classes.authorInfo}>
                <h5>{`${author.first_name} ${author.last_name}`}</h5>
                {author.location && (
                  <span>
                    <LocationOnIcon />
                    {author.location}
                  </span>
                )}
                {author.created_at && (
                  <span>
                    <EventIcon />
                    Joined: {moment(author.created_at).format('LL')}
                  </span>
                )}
                {author.pinterest_link && (
                  <Link
                    href={author.pinterest_link}
                    rel='noreferrer noopener'
                    target='_blank'
                  >
                    <IconButton
                      className={classes.pinterestButton}
                      variant='contained'
                      color='primary'
                      size='small'
                    >
                      <PinterestIcon />
                    </IconButton>
                  </Link>
                )}
              </div>
            </div>
          </Grid>
          <Grid item>
            <div>
              <BannerAd adKey={'banner5'} />
            </div>
          </Grid>
          <Grid item>
            <div className={classes.recentContainer}>
              <Typography component='h2' variant='h5'>
                Recent
                <Divider />
              </Typography>
              {props.articles && props.articles.data && (
                <div>
                  {props.articles.data.map((article) => {
                    return (
                      <div key={article._id} className={classes.recentWrapper}>
                        <div className={classes.recentInfo}>
                          <div className={classes.recentLine}>
                            {moment(article.created_at).format('LL')}
                          </div>
                          <h4 className={classes.recentLine}>
                            <Link
                              href={`/${article.category}/article/${article._id}`}
                            >
                              {article.title}
                            </Link>
                          </h4>
                        </div>
                        <Avatar
                          src={article.main_image}
                          alt='thumbnail'
                          className={classes.recentPhoto}
                        />
                        <div width='200px'>
                          <Divider flexItem={true} variant={'fullWidth'} />
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </Grid>
          <Grid item>
            <div>
              <BannerAd adKey={'banner6'} />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ArticleBody;

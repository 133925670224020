import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Home from '../components/blog/Blog'
import { loadArticles, initArticles } from '../actions/articles'
import { loadCategories } from '../actions/categories'
import { loadAuthors } from '../actions/users'

const mapStateToProps = state => {
  return {
    ...state,
  }
}

const mapDispatchToProps = dispatch => (bindActionCreators({
  loadArticles,
  loadCategories,
  initArticles,
  loadAuthors,
}, dispatch))

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home)

import React from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import About from '../about'
import Category from '../../containers/category'
import Article from '../../containers/article'
import Blog from '../../containers/home';
import DMCA from '../DMCA';
import CookiePolicy from '../CookiePolicy';
import Disclaimer from '../Disclaimer';
import PrivacyPolicy from '../PrivacyPolicy';
import TermsOfUse from '../TermsOfUse';
import AdminArticle from '../../containers/adminArticle';
import Login from '../../containers/login';
import PrivateRoute from './PrivateRoute';


const PageRouter = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Blog} />
        <Route path="/about" component={About} />
        <Route exact path="/articles/:category" component={Category} />
        <Route path="/:category/article/:id" component={Article} />
        <Route path="/digital-millennium-copyright-act-notice" component={DMCA} />
        <Route path="/cookie-policy" component={CookiePolicy} />
        <Route path="/disclaimer" component={Disclaimer} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/terms-of-use" component={TermsOfUse} />

        <Route path="/admin/login" component={Login} />
        <PrivateRoute path="/admin/edit/:id" component={AdminArticle} />

        <Redirect to="/" />
      </Switch>
    </Router>
  )
}

export default PageRouter;

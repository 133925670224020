import userService from '../services/userService';
import { showSnackbar } from './snackbar';

export const loadSingleAuthor = (query) => {
  return (dispatch) => {
    return userService.getUsers(query).then((data) => {
      dispatch({
        type: 'LOAD_SINGLE_USER',
        data
      })
    })
  }
}

export const loadAuthors = (query = {}) => {
  return (dispatch) => {
    return userService.getUsers(query).then((data) => {
      dispatch({
        type: 'LOAD_USERS',
        data
      })
      return data;
    })
  }
}

export const login = (query) => {
  return (dispatch) => {
    return userService.login(query).then((data) => {
      if (data.success === false) {
        dispatch({
          type: 'LOAD_AUTH_ERROR',
        })
        dispatch(showSnackbar({
          message: 'Login failed',
          variant: 'error'
        }));
      } else {
        dispatch({
          type: 'LOAD_AUTH_USER',
          data
        })
      }
      return data;
    })
  }
}

import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import MuiAccordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
imageBackground: {
  backgroundSize: 'contain',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  width: '100%',
},
accordion: {
  top: '0px',
  position: 'absolute',
  background: 'transparent',
  border: '0px,'
},
accordionDetails: {
  background: 'black',
  opacity: '.5',
  color: 'white',
  display: 'block',
}
}));

const Accordion = withStyles({
  root: {
    border: 'none',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

export default function ImageModal({ isOpen, handleModalClose, imageUrl, pinTarget }) {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleModalClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth='md'
        fullWidth={true}
      >
      <div>
        <img src={imageUrl} className={classes.imageBackground} />
        <Accordion className={classes.accordion}>
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
        />
        <AccordionDetails className={classes.accordionDetails}>
        <Typography component="h6" variant="h6">
          article: {pinTarget}
        </Typography>
        <Typography component="h6" variant="h6">
          image: {imageUrl}
        </Typography>

        </AccordionDetails>
      </Accordion>
      </div>
      </Dialog>
    </div>
  );
}

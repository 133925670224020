import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Article from '../components/adminArticle'
import {
  loadArticles,
  loadSingleArticle,
  saveArticle,
  uploadImage,
  deleteImage,
  initArticles
} from '../actions/articles'
import { loadCategories } from '../actions/categories';
import { generateCategoryRSSFeed } from '../actions/rssFeed';
import { loadSingleAuthor, loadAuthors } from '../actions/users';
import { showSnackbar } from '../actions/snackbar';

const mapStateToProps = state => {
  return state.articles || {};
}

const mapDispatchToProps = dispatch => (bindActionCreators({
  loadArticles,
  loadSingleArticle,
  loadCategories,
  loadSingleAuthor,
  saveArticle,
  uploadImage,
  deleteImage,
  loadAuthors,
  generateCategoryRSSFeed,
  showSnackbar,
  initArticles,
}, dispatch));

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Article)

import postService from '../services/postService';
import { showSnackbar } from './snackbar';

export const loadArticles = ({ query, isRecent }) => {
  return (dispatch, getState) => {
    let [subdomain] = window.location.hostname.split('.');
    const state = getState();
    if (subdomain && subdomain !== 'www' && subdomain !== 'localhost') {
      const domainUser = state.users.userList.find(user => user.username === subdomain);
      if (domainUser) query.author = domainUser._id;
    }
    return postService.getPosts({ query, isRecent }).then((data) => {
      dispatch({
        type: 'LOAD_ARTICLES',
        data
      });
      return data;
    })
  }
}

export const loadSingleArticle = (query) => {
  return (dispatch) => {
    return postService.getPost(query).then((data) => {
      dispatch({
        type: 'LOAD_SINGLE_ARTICLE',
        data
      });
      return data;
    })
  }
}

export const initArticles = () => {
  return (dispatch) => {
    dispatch({
      type: 'LOAD_ARTICLES_INIT',
    });
  }
}

export const saveArticle = (options) => {
  return (dispatch) => {
    return postService.setPost(options).then((data) => {
      if (data.message === 'unauthorized') {
        dispatch(showSnackbar({
          message: data.message,
          variant: 'error'
        }));
      } else {
        dispatch({
          type: 'LOAD_SINGLE_ARTICLE',
          data
        });
      }
      return data;
    });
  }
}

export const uploadImage = (options) => {
  return (dispatch) => {
    return postService.uploadImage(options).then((data) => {
      if (data.message === 'unauthorized') {
        dispatch(showSnackbar({
          message: data.message,
          variant: 'error'
        }));
        return data;
      }
      return data;
    })
  }
}

export const deleteImage = (options) => {
  return (dispatch) => {
    return postService.deleteImage(options).then((data) => {
      if (data.message === 'unauthorized') {
        dispatch(showSnackbar({
          message: data.message,
          variant: 'error'
        }));
      }
      return data;
    })
  }
}

import { combineReducers } from 'redux';
import articles from './articles'
import categories from './categories'
import users from './users'
import snackbar from './snackbar'

const reducer = combineReducers({
    articles,
    categories,
    users,
    snackbar,
});

export default reducer

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Login from '../components/login'

import { login, } from '../actions/users'

const mapStateToProps = state => {
  return {
    ...state.users.authUser,
  }
}

const mapDispatchToProps = dispatch => (bindActionCreators({ login }, dispatch));

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login)

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import _isEmpty from 'lodash/isEmpty';
import { Image } from 'react-bootstrap'
import RichTextEditor from 'react-rte';
import Divider from '@material-ui/core/Divider';
import Container from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import PhotoSizeSelectActualIcon from '@material-ui/icons/PhotoSizeSelectActual';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ImagePicker from '../../react-image-picker/react-image-picker'
import Switch from '@material-ui/core/Switch';
import ArticleBody from '../article/articleBody';
import ReactPlayer from 'react-player/lazy';
import moment from 'moment';
import {
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { useAuth } from "../../context/auth";
import socket from '../../socket';
import '../../react-image-picker/index.css'

const useStyles = makeStyles((theme) => ({
  marginTop: {
    marginTop: theme.spacing(2),
  },
  marginRight: {
    marginRight: theme.spacing(2),
  },
  chipsWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  addChipWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  controlWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexWrap: 'no-wrap',
  },
  imageField: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  imageFieldWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  select: {
    width: '250px',
  },
  upload: {
    display: 'none !important',
  },
  photo: {
    maxWidth: '275px',
    borderRadius: '10px',
  },
  imageButton: {
    marginRight: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  button: {
    margin: theme.spacing(.7),
  },
  videoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  deleteVideoButton: {
    position: 'relative',
    top: '70px',
    left: '5px',
    zIndex: '1000'
  }
}));

const AdminDashboard = (props) => {
  const { saveArticle, uploadImage, deleteImage, generateCategoryRSSFeed, showSnackbar } = props;
  const classes = useStyles();
  const { setAuthToken } = useAuth();
  const [article, setArticle] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [body, setBody] = useState(RichTextEditor.createEmptyValue());
  const [mainImage, setMainImage] = useState('');
  const [tags, setTags] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [newTag, setNewTag] = useState('');
  const [origMainImage, setOrigMainImage] = useState(null)
  const [imageCount, setImageCount] = useState('');
  const [images, setImages] = useState([]);
  const [image, setImage] = useState({});
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setCategory] = useState('');
  const [authors, setAuthors] = useState([]);
  const [selectedAuthor, setAuthor] = useState({});
  const [pickerImages, setPickerImages] = useState([]);
  const [mainImagePickerImage, setMainImagePickerImage] = useState({});
  const [deleteImagePickerImage, setDeleteImagePickerImage] = useState(null);
  const [previewSwitch, setPreviewSwitch ] = useState(false);
  const [uploadImageFile, setUploadImageFile] = useState(null);
  const [videos, setVideos] = useState([]);
  const [videoUrl, setVideoUrl] = useState('');
  const [publishDate, setPublishDate] = useState(moment().format());
  const [startPinDate, setStartPinDate] = useState(moment().startOf('year'));
  const [endPinDate, setEndPinDate] = useState(moment().endOf('year'));
  const [isDraft, setIsDraft] = useState(false);

  const _id = props.match.params.id;

  socket.on('video upload success', (newArticle) => {
    showSnackbar({
      message: 'Video upload complete',
      variant: 'success'
    });
    setVideos(newArticle.videos);
    setArticle(newArticle);
  });

  if (_id !== 'add') {

    if (!props.init) {
      props.loadSingleArticle({ _id }).then(resp => {
        setArticle(resp[0]);
        setTitle(resp[0].title)
        setDescription(resp[0].description)
        setBody(RichTextEditor.createValueFromString(resp[0].body, 'html'))
        setTags(resp[0].tags)
        setMainImage(resp[0].main_image)
        setOrigMainImage(resp[0].main_image)
        setImageCount(resp[0].images.length)
        setImages(resp[0].images)
        setVideos(resp[0].videos || [])
        setVideoUrl(resp[0].videoUrl)
        if (resp[0].publish_date) {
          setPublishDate(resp[0].publish_date)
        }
        if (resp[0].start_pin_date) {
          setStartPinDate(resp[0].start_pin_date)
        }
        if (resp[0].end_pin_date) {
          setEndPinDate(resp[0].end_pin_date)
        }
        const isDraftOrDefault = resp[0].is_draft || false;
        setIsDraft(isDraftOrDefault)
        const mappedImages = [  ...resp[0].images ].map((image, i) => ({src: image.Location, key: image.Key, value: i }))
        setPickerImages(mappedImages)
        const initMainImage = mappedImages.find(i => i.src === resp[0].main_image) || {};
        setMainImagePickerImage(initMainImage)
        setCategory(resp[0].category)
        props.loadSingleAuthor({ _id: resp[0].author });
        props.loadAuthors().then(authors => {
          props.loadArticles({ query: { category: resp[0].category }, isRecent: true });
          setAuthors(authors);
          setIsLoading(false)
          if (resp[0].author) {
            const defaultAuthor = authors.find(a => a._id == resp[0].author);
            if (defaultAuthor) setAuthor(defaultAuthor);
          }
        })
        props.loadCategories().then(categories => {
          setIsLoading(false)
          setCategories(categories);
          if (resp[0].category) {
            const defaultCategory = categories.find(c => c.name == resp[0].category);
            if (defaultCategory) setCategory(defaultCategory);
          }
        });
      });
    }
  } else {
    if (!props.init) {
      const localStorageArticle = window.localStorage.getItem('article');
      props.initArticles();

      props.loadAuthors().then(authors => {
        setIsLoading(false)
        setAuthors(authors);
      })
      props.loadCategories().then(categories => {
        setIsLoading(false)
        setCategories(categories);

        const article = localStorageArticle ? JSON.parse(localStorageArticle) : null;
        if (article) {
          setTitle(article.title)
          setDescription(article.description)
          setBody(RichTextEditor.createValueFromString(article.body, 'html'))
          setTags(article.tags)
          if (article.publish_date) {
            setPublishDate(article.publish_date)
          }
          if (article.start_pin_date) {
            setStartPinDate(article.start_pin_date)
          }
          if (article.end_pin_date) {
            setEndPinDate(article.end_pin_date)
          }
          const isDraftOrDefault = article.is_draft || false;
          setIsDraft(isDraftOrDefault)
        }
      });
    }
  }

  const deleteTag = (t) => {
    const newTags = tags.filter(tag => tag !== t);
    setTags(newTags);
  }

  const addTag = () => {
    const newTags = [ ...tags, newTag ];
    setTags(newTags);
    setNewTag('');
  }

  const renderTags = () => {
    return tags.map(tag => (
      <Chip
        label={tag}
        onDelete={() => deleteTag(tag)}
        key={tag}
      />
    ));
  }

  const handleSelectedFile = e => {
    e.preventDefault();
    setUploadImageFile(e.target.files[0]);
  };

  const handleUpload = event => {
    setIsLoading(true)
    event.preventDefault();
    const data = new FormData();
    data.append("file", uploadImageFile);
    uploadImage({
      file: data,
      _id,
      category: selectedCategory.name,
      key: uploadImageFile.Key,
     }).then(uploadData => {
       if (uploadData.message && uploadData.message === 'uploading video') {
         setUploadImageFile(null);
         setIsLoading(false);
         showSnackbar({
           message: 'Video uploading...',
           variant: 'info'
         });
       } else {
         setImages([ ...images, { Location: uploadData.data.Location, Key: uploadData.data.Key } ])
         setPickerImages([ ...pickerImages, { src: uploadData.data.Location, key: uploadData.data.Key, value: pickerImages.length } ])
         setUploadImageFile(null);
         setIsLoading(false)
       }
    }).catch((err) => {
      console.log(err);
    });
  };

  const setImageValue = ({ idx, value }) => {
    const newImages = [...images];
    newImages.splice(idx, 1, value);
    setImages(newImages);
  }

  const addImageField = () => {
    const newImages = [...images, ''];
    setImages(newImages);
  }

  const onPickMainImage = (image) => {
    setMainImagePickerImage(image);
  }

  const onPickDeleteImage = (image) => {
    setDeleteImagePickerImage(image);
  }

  const setPost = () => {
    return new Promise((resolve) => {
      setIsLoading(true);
      const reqBody = {
        title,
        body: body.toString('html'),
        description,
        main_image: mainImage,
        tags,
        images,
        videos,
        category: selectedCategory.name,
        author: selectedAuthor._id,
        isHTMLBody: true,
        videoUrl,
        publish_date: publishDate,
        is_published: moment().isSameOrAfter(moment(publishDate)),
        is_draft: isDraft,
        start_pin_date: startPinDate,
        end_pin_date: endPinDate
      }

      if (
        !startPinDate
        || !endPinDate
        || !selectedCategory
        || !selectedAuthor
        || !publishDate
      ) {
        window.localStorage.setItem('article', JSON.stringify(reqBody));
        showSnackbar({
          message: 'Please fill out all fields and try again',
          variant: 'error'
        });
        setIsLoading(false);
        return;
      }

      if (_id === 'add') {
        window.localStorage.setItem('article', JSON.stringify(reqBody));
        saveArticle({ body: reqBody, method: 'POST', }).then(resp => {
          if (resp.message === 'unauthorized') {
            setAuthToken({ token: null });
            return;
          }
          window.location.replace(`/admin/edit/${resp._id}`);
          window.localStorage.setItem('article', null);
          setIsLoading(false);
          generateCategoryRSSFeed({ userId: reqBody.author, category: reqBody.category });
          resolve({ _id: resp._id, category: selectedCategory.name });
        });
      } else {
        saveArticle({ body: { ...reqBody, _id }, id: _id, method: 'PUT', category: selectedCategory.name }).then(resp => {
          if (resp.message === 'unauthorized') setAuthToken({ token: null });
          setIsLoading(false);
          generateCategoryRSSFeed({ userId: reqBody.author, category: reqBody.category })
          resolve({_id, category: selectedCategory.name })
        });
      }
    });

  }

  const handleDoneEditing = async() => {
    const resp = await setPost();
    window.location.replace(`/${resp.category}/article/${resp._id}`);
  };

  const deleteArticleImage = () => {
    setIsLoading(true);
    const key = pickerImages[deleteImagePickerImage.value].key;
    deleteImage({
      _id,
      category: selectedCategory.name,
      key,
    }).then(resp => {
      setDeleteImagePickerImage(null);
      const newImages = images.filter(image => image.Key !== key)
      const newPickerImages = pickerImages.filter(image => image.key !== key)
      setImages(newImages);
      setPickerImages(newPickerImages)
      setIsLoading(false)
    })
  };

  const deleteArticleVideo = ({ key }) => {
    setIsLoading(true);
    deleteImage({
      _id,
      category: selectedCategory.name,
      key,
      mediaType: 'video'
    }).then(resp => {
      const newVideos = videos.filter(video => video.Key !== key)
      setVideos(newVideos);
      setIsLoading(false)
    })
  };

  const handlePublishDateChange = (date) => {
    const newDate = date.format();
    setPublishDate(newDate);
  };
  const handleStartPinDateChange = (date) => {
    const newDate = date.format('MM/DD');
    setStartPinDate(newDate);
  };
  const handleEndPinDateChange = (date) => {
    const newDate = date.format('MM/DD');
    setEndPinDate(newDate);
  };

  const newArticle = {
    ...article,
    images,
    body: body.toString('html'),
    title,
    description,
    main_image: mainImage,
    tags,
    category: selectedCategory.name,
    author: selectedAuthor._id,
    isHTMLBody: article.isHTMLBody,
  };

  return (
    <div>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className={classes.controlWrapper}>
        <FormControlLabel
          className={classes.button}
          control={<Switch
                     checked={previewSwitch}
                     onChange={() => {
                       const newPreviewVal = !previewSwitch;
                       setPreviewSwitch(newPreviewVal)}
                     }
                     color="primary"
                     name="previewSwitch"
                     inputProps={{ 'aria-label': 'preview checkbox' }}
                   />}
          label="Preview"
        />
        <Button
          size="small"
          variant="contained"
          color="primary"
          startIcon={<SaveIcon />}
          onClick={setPost}
          className={classes.button}
        >
          Save
        </Button>
        <Button
          size="small"
          variant="contained"
          color="primary"
          startIcon={<DoneOutlineIcon />}
          onClick={handleDoneEditing}
          className={classes.button}
        >
          Done
        </Button>
      </div>
      { previewSwitch ?
        <ArticleBody {...props} author={selectedAuthor} article={newArticle} authTokens={false}/>
        :
        <div>
          <FormControlLabel
            control={
              <Switch
               checked={isDraft}
               onChange={() => {
                 const newIsDraftVal = !isDraft;
                 setIsDraft(newIsDraftVal)}
               }
               color="primary"
               name="previewSwitch"
               inputProps={{ 'aria-label': 'preview checkbox' }}
             />
            }
            label="Save As Draft"
          />
          <FormControl fullWidth variant="outlined" label='Category'>
            <InputLabel htmlFor="categorySelect">Author</InputLabel>
            <Select
              label="Author"
              variant="outlined"
              id='authorSelect'
              value={selectedAuthor._id || ''}
              onChange={(e) => {
                const selectedA = authors.find(a => a._id == e.target.value);
                setAuthor(selectedA);
              }}
              className={classes.select}
            >
            {
              authors.map(a => {
                return (<MenuItem key={a._id} value={a._id}>{`${a.first_name} ${a.last_name}`}</MenuItem>)
              })
            }
            </Select>
          </FormControl>
          <FormControl fullWidth variant="outlined" label='Category' className={classes.marginTop}>
            <InputLabel htmlFor="categorySelect">Category</InputLabel>
            <Select
              label="Category"
              variant="outlined"
              id='categorySelect'
              value={selectedCategory._id || ''}
              onChange={(e) => {
                const selectedC = categories.find(c => c._id == e.target.value);
                setCategory(selectedC);
              }}
              className={classes.select}
            >
            {
              categories.map(c => {
                return (<MenuItem key={c._id} value={c._id}>{c.display}</MenuItem>)
              })
            }
            </Select>
          </FormControl>
          <FormControl className={classes.marginRight}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                inputVariant="outlined"
                format="MM/DD/YYYY"
                minDate={moment().format()}
                margin="normal"
                id="date-picker-inline"
                label="Publish Date"
                value={publishDate}
                onChange={handlePublishDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                // Subtract 5 minutes for new articles that will have the same moment
                // which would disable it
                disabled={moment().subtract(5, 'minutes').isAfter(moment(publishDate))}
              />
          </FormControl>
          <FormControl className={classes.marginRight}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                inputVariant="outlined"
                format="MM/DD"
                maxDate={endPinDate}
                margin="normal"
                id="date-picker-inline"
                label="Start Pin Date"
                value={startPinDate}
                onChange={handleStartPinDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
          </FormControl>
          <FormControl className={classes.marginRight}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                inputVariant="outlined"
                format="MM/DD"
                margin="normal"
                minDate={startPinDate}
                id="date-picker-inline"
                label="End Pin Date"
                value={endPinDate}
                onChange={handleEndPinDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
          </FormControl>
          <div className={classes.addChipWrapper}>
            <FormControl size='medium'>
              <TextField
                label="Add Tag"
                variant="outlined"
                value={newTag}
                onChange={(e) => {
                  setNewTag(e.target.value)
                }}
                onKeyDown={(e) => {
                  if(e.keyCode == 13){
                    addTag();
                  }
                }}
              />
            </FormControl>
            <IconButton
              aria-label="add article"
              color="primary"
              disabled={!newTag}
              onClick={addTag}
            >
              <AddCircleIcon />
            </IconButton>
          </div>
          <div className={classes.chipsWrapper}>
            {renderTags()}
          </div>
          <div className={classes.imageFieldWrapper}>
            <div className={classes.marginTop}>
                { _id !== 'add' &&
                <div>
                  <InputLabel>Edit Media</InputLabel>
                  {!deleteImagePickerImage && !uploadImageFile &&
                    <Button
                      disabled={origMainImage === mainImage}
                      className={classes.imageButton}
                      size="small"
                      variant="contained"
                      color="primary"
                      startIcon={<SaveIcon />}
                      onClick={setPost}
                    >
                      Save
                    </Button>
                  }
                  <input
                    accept="image/*,video/*"
                    className={classes.upload}
                    onChange={handleSelectedFile}
                    id="contained-button-file"
                    type="file"
                    key={uploadImageFile}
                  />
                  { !!uploadImageFile ?
                    <div>
                      <Button
                      className={classes.imageButton}
                      size='small'
                      variant="contained"
                      startIcon={<SaveIcon />}
                      onClick={handleUpload}
                      color="primary"
                      component="span"
                    >
                        Save
                      </Button>
                      <p>{uploadImageFile.name}</p>
                    </div>
                    :
                    <label htmlFor="contained-button-file">
                      <Button
                        className={classes.imageButton}
                        size="small"
                        variant="contained"
                        color="primary"
                        component="span"
                        startIcon={<AddPhotoAlternateIcon/>}
                      >
                        Upload
                      </Button>
                    </label>
                  }
                  { !deleteImagePickerImage && pickerImages.length > 0 &&
                    <Button
                      className={classes.imageButton}
                      size="small"
                      variant="contained"
                      color="secondary"
                      startIcon={<DeleteIcon />}
                      onClick={() => setDeleteImagePickerImage({})}
                    >
                      Remove Images
                    </Button>
                  }
                  { !!deleteImagePickerImage &&
                    <Button
                      className={classes.imageButton}
                      size="small"
                      variant="contained"
                      color="primary"
                      startIcon={<SaveIcon />}
                      onClick={deleteArticleImage}
                      disabled={_isEmpty(deleteImagePickerImage)}
                    >
                      Save
                    </Button>
                  }
                  { !!deleteImagePickerImage &&
                    <IconButton
                      size='small'
                      color="secondary"
                      onClick={() => setDeleteImagePickerImage(null)}
                    >
                      <ClearIcon />
                    </IconButton>
                  }
                  { !deleteImagePickerImage &&
                    <div>
                      <p>Select main image and click save</p>
                      <ImagePicker
                        id='mainImagePicker'
                        initSelected={mainImagePickerImage}
                        images={pickerImages}
                        onPick={(image) => {
                          onPickMainImage(image)
                          setMainImage(image.src)
                        }}
                      />
                    </div>
                  }
                  { !!deleteImagePickerImage &&
                    <div>
                      <p>Select the image you would like to delete</p>
                      <ImagePicker
                        isDeletePicker
                        initSelected={deleteImagePickerImage}
                        id='imageDeleter'
                        images={pickerImages}
                        onPick={(image) => {
                          onPickDeleteImage(image)
                        }}
                      />
                    </div>
                  }
                </div>
              }
            </div>
          </div>
          {
            videos.map(video =>
              <div key={video.Key}>
                <IconButton
                  className={classes.deleteVideoButton}
                  color='primary'
                  onClick={() => deleteArticleVideo({ key: video.Key })}
                >
                  <DeleteIcon />
                </IconButton>
                <ReactPlayer
                  url={video.Location}
                  controls={true}
                  className={classes.videoWrapper}
                />
              </div>
            )
          }
          <FormControl fullWidth className={classes.marginTop}>
            <TextField
              label="Title"
              variant="outlined"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </FormControl>
          <FormControl fullWidth className={classes.marginTop}>
            <TextField
              label="Description"
              variant="outlined"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </FormControl>
          <FormControl fullWidth className={classes.marginTop}>
            <TextField
              label="Video URL"
              variant="outlined"
              value={videoUrl}
              onChange={(e) => setVideoUrl(e.target.value)}
            />
          </FormControl>
          <FormControl fullWidth className={classes.marginTop}>
            <RichTextEditor
              value={body}
              onChange={setBody}
            />
          </FormControl>

        </div>
      }
    </div>
  );
};

export default AdminDashboard;

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import GitHubIcon from '@material-ui/icons/GitHub';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import MainFeaturedPost from './MainFeaturedPost';
import Sidebar from './Sidebar';
import Entry from '../entry'
import AuthorBio from './AuthorBio';
import { useAuth } from "../../context/auth";

const useStyles = makeStyles((theme) => ({
  mainGrid: {
    marginTop: theme.spacing(3),
  },
}));

export default function Blog(props) {
  const classes = useStyles();
  const [mainFeaturedPost, setMainFeaturedPost] = useState(null);
  const [author, setAuthor] = useState(null);
  const [viewIsDraft, setViewIsDraft] = useState(false);
  const [query, setQuery] = useState({});
  const { articles = {}, categories = {} } = props;
  const category = props.match.params.category;
  const articlesData = articles.data || [];
  const categoriesData = categories.data || [];
  const getRandomInt = (max) => {
    return Math.floor(Math.random() * Math.floor(max));
  }

  const { authTokens } = useAuth();

  if (category) {
    setQuery({category});
  }

  useEffect(() => {
    if (!articles.init) {
      // Do all the init stuff
      props.initArticles();
      props.loadAuthors().then((authors) => {
        const [subdomain] = window.location.hostname.split('.');
        const subdomainAuthor = authors.find(a => a.username === subdomain);

        if (subdomain && subdomainAuthor) {
          setAuthor(subdomainAuthor);
        };

        const newQuery = { ...query, is_draft: false };

        props.loadArticles({ query: newQuery, isRecent: true }).then(resp => {
          if (!mainFeaturedPost) {
            setMainFeaturedPost(resp[getRandomInt(resp.length)]);
          }
        });
      });

      props.loadCategories();
    } else {
      // just reload articles with upgraded query because we have everything else
      props.loadArticles({ query, isRecent: true }).then(resp => {
        if (!mainFeaturedPost) {
          setMainFeaturedPost(resp[getRandomInt(resp.length)]);
        }
      });
    }
  }, [query]);

  return (
    <React.Fragment>
      {authTokens &&
        <FormControlLabel
          control={
            <Switch
             checked={viewIsDraft}
             onChange={() => {
               const newViewIsDraftVal = !viewIsDraft;
               setViewIsDraft(newViewIsDraftVal)
               const newQuery = { ...query, is_draft: newViewIsDraftVal };
               setQuery(newQuery);

               props.loadArticles({ query: newQuery, isRecent: true }).then(resp => {
                 if (!mainFeaturedPost) {
                   setMainFeaturedPost(resp[getRandomInt(resp.length)]);
                 }
               });
             }

             }
             color="primary"
             name="draftSwitch"
             inputProps={{ 'aria-label': 'preview checkbox' }}
           />
          }
          label="View Drafts"
        />
      }
      <Grid container spacing={4} justify='center'>
        {!!author &&
          <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
            <AuthorBio author={author} />
          </Grid>
        }
      </Grid>
      <Grid container spacing={4} justify='center'>
        {articlesData.map((post) => (
          <Entry key={post._id} post={post} />
        ))}
      </Grid>
    </React.Fragment>
  );
}

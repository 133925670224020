import React from 'react'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import Ad from '../ad'
import Entry from '../entry'


const renderArticles = (articles) => {
  if (articles) {
    return articles.map((article) => {
      return (
        <Entry post={article} key={article._id}/>
      )
    })
  } else {
    return [];
  }

}
const useStyles = makeStyles((theme) => ({
  categoryHeader: {
    marginBottom: '10px',
  }
}));
const Category = props => {
  const classes = useStyles();
  const category = props.match.params.category;
  
  document.title = category;

  if (!props.articles.init) {
    props.initArticles();
    props.loadAuthors().then(() => {
      props.loadArticles({ query: { category } });
    });
  }
  if (!props.categories.data) {
    props.loadCategories();
  }
  const articles = props.articles ? props.articles.data : [];
  const categories = props.categories ? props.categories.data || [] : [];
  const selectedCategory = categories.find((cat) => {
    return cat.name === category
  }) || {};
    return (
      <div>
        <br />
        <div className="container padding-0">
          <Typography className={classes.categoryHeader} align="center" component="h3" variant="h3">
            {selectedCategory.display}
          </Typography>
          <Grid container spacing={4} justify='center'>
            {renderArticles(articles)}
          </Grid>
        </div>
      </div>
    )
}

export default Category

const snackbar = (state = {}, action) => {
  switch (action.type) {
    case 'SNACKBAR_OPEN':
      return {
        ...state,
        isOpen: true,
        message: action.message,
        variant: action.variant || 'info'
      };
    case 'SNACKBAR_CLEAR':
      return {
        ...state,
        isOpen: false,
        message: '',
        type: ''
      };
    default:
      return state;
  }
};

export default snackbar;

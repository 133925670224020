import { connect } from 'react-redux'
import Snackbar from '../components/snackbar'

const mapStateToProps = state => {
  return state.snackbar;
}

const mapDispatchToProps = dispatch => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Snackbar)

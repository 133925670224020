import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import EmailIcon from '@material-ui/icons/Email';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {
  Col,
  Row
} from 'react-bootstrap'

const useStyles = makeStyles((theme) => ({
  banner: {
    position: 'relative',
    color: theme.palette.common.white,
    marginBottom: theme.spacing(4),
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '15% left',
    textAlign: 'left',
    alignItems: 'center',
    minHeight: '50vh',
    textTransform: 'uppercase',
  },
  header: {
    color: theme.palette.primary.main,
  },
  imageFit: {
    display: 'block',
    width: '100%',
  },
  image: {
    width: '100%',
  },
  inner: {
    color: theme.palette.common.white,
    maxWidth: '65em',
    width: 'calc(100% - 6em)',
    margin: '0 auto',
    position: 'relative',
    lineHeight: 1.5,
  },
  sectionOdd: {
    marginBottom: theme.spacing(10),
    backgroundColor: '#e8e8e8',
    padding: theme.spacing(4),
  },
  sectionEven: {
    marginBottom: theme.spacing(10),
  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,.4)',
  },
  footer: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  }
}));

const About = props => {
  const classes = useStyles();

  return (
    <div>
      <Row>
        <Col lg={12}>

          <Grid container className={classes.banner} style={{ backgroundImage: `url(/static/images/banner.jpg)` }}>
            <div className={classes.overlay} />
              <div className={classes.inner}>
                <Typography component="h3" variant="h3" gutterBottom>
                Live.<br/>Experience.<br/>Share.<br/>Get Paid!
                </Typography>
              </div>
          </Grid>

          <section className={classes.sectionOdd}>
            <Typography className={classes.header} component="h2" variant="h4" gutterBottom>
              Who we are
            </Typography>
            <Typography component="h6" variant="h6" gutterBottom>
              We are Blogiply. We are a innovation in the blogging industry. We are a social blogging co-op designed to make money for bloggers who do not have the time or energy to set up a blogging site for themselves.
              We are running a limited time test with some very inspirational bloggers, however we are excited and looking forward to opening our doors for new approved bloggers to join the co-op. If you are interested, please drop us a line via the get in touch form below.
           </Typography>
          </section>

          <Grid className={classes.sectionEven} container alignItems='center' spacing='3'>
            <Grid item md={6}>
                <div class="content">
                  <Typography className={classes.header} component="h2" variant="h4" gutterBottom>
                    Work on your time.
                  </Typography>
                  <div className={classes.imageFit}>
                    <img className={classes.image} src="/static/images/pic01.jpg" alt="" />
                  </div>
                  <Typography component="h6" variant="h6" gutterBottom>
                    Life is busy. Here at Blogiply we value life and the experiences that go along with it. We want you to experience every part of that. Then when the amazing parts happen, come on over and share in your own time. There are not time requirements or quotas to be met on how many articles are written. You control your success in your own time.
                  </Typography>
                </div>
              </Grid>
              <Grid item md={6}>
                <div class="content">
                  <Typography className={classes.header} component="h2" variant="h4" gutterBottom>
                    Write about your passions.
                  </Typography>
                  <div className={classes.imageFit}>
                    <img className={classes.image} src="/static/images/pic02.jpg" alt="" />
                  </div>
                  <Typography component="h6" variant="h6" gutterBottom>
                    Write about what inspires you. If you love food, write about it and share the recipe. Similarly if you are a gear head and love engines, lets here about it. Travel the world and share all the stops along the way, or sit in your living room and share sewing tips, mom tips, diy project tips or anything else that others may find valuable or interesting.
                  </Typography>
                </div>
              </Grid>
          </Grid>

          <section className={classes.sectionOdd}>
            <Typography className={classes.header} component="h2" variant="h4" gutterBottom>
              How it works
            </Typography>
            <Typography component="h6" variant="h6" gutterBottom>
             You sign up, you get approved, and you start writing about whatever it is that you love and are passionate about. We generate revenue from the ads displayed on our page. If you write good articles, then more people will see the ads. If more people see the ads then you make more money. So get our there and live life, experience new things, and come here and share with the world. And of get paid along the way.
            </Typography>
          </section>


          <div className={classes.footer}>
              <Typography component="h2" variant="h5" gutterBottom>
                GET IN TOUCH
              </Typography>
              <FormControl className={classes.formControl} fullWidth>
                <TextField
                  label="Name"
                  variant="outlined"
                />
              </FormControl>

              <FormControl className={classes.formControl} fullWidth>
                <TextField
                  label="Email"
                  variant="outlined"
                />
              </FormControl>

              <FormControl className={classes.formControl} fullWidth>
                <TextField
                  placeholder=''
                  label="Message"
                  multiline
                  rows={10}
                  variant="outlined"
                />
              </FormControl>
              <Button
                startIcon={<EmailIcon />}
                color='primary'
              >
                Send
              </Button>
          </div>
        </Col>
      </Row>
    </div>
  )
}



export default About;


import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Backdrop
} from '@material-ui/core';
import { AccountCircle, Fingerprint } from '@material-ui/icons'
import { Alert } from '@material-ui/lab';
import { Redirect } from "react-router-dom";

import { useAuth } from "../../context/auth";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  error: {
    margin: theme.spacing(2)
  }
}));

const Login = (props) => {
  const { login, error } = props;
  const classes = useStyles();
  const [ username, setUsername ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ loading, setLoading ] = useState(false);
  const { setAuthToken, authTokens } = useAuth();
  const [isLoggedIn, setLoggedIn] = useState(!!authTokens);

  const loginHandler = () => {
    setLoading(true);
    login({username, password}).then((resp) => {
      setLoading(false);
      if (!!resp.response) {
        setAuthToken(resp.response.result);
        setLoggedIn(true);
      }
    })
  };

  if (isLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <div>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {error &&
        <div className={classes.error}>
          <Alert severity="error">Invalid username or password. Please try again.</Alert>
        </div>
      }

      <Grid container spacing={8} alignItems="flex-end">
         <Grid item>
             <AccountCircle />
         </Grid>
         <Grid item md={true} sm={true} xs={true}>
             <TextField onChange={(e) => setUsername(e.target.value)} id="username" label="Username" type="email" fullWidth autoFocus required />
         </Grid>
     </Grid>
     <Grid container spacing={8} alignItems="flex-end">
         <Grid item>
             <Fingerprint />
         </Grid>
         <Grid item md={true} sm={true} xs={true}>
             <TextField onChange={(e) => setPassword(e.target.value)} id="username" label="Password" type="password" fullWidth required />
         </Grid>
     </Grid>
     <Grid container justify="center" style={{ marginTop: '10px' }}>
        <Button
          disabled={!username || !password}
          variant="outlined"
          color="primary"
          style={{ textTransform: "none" }}
          onClick={loginHandler}
        >
          Login
        </Button>
     </Grid>
    </div>
  );
};

export default Login;

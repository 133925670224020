import 'whatwg-fetch';
import qs from 'qs';

const getUsers = async (query) => {
  let users = [];
  let params = '';
  let url = '/api/users';
  if (query) {
    params = qs.stringify(query);
    url = `${url}?${params}`;
  }
  const response = await fetch(url)
  .then(function(response) {
    return response.json()
  });
  if (response.response && response.response.results) {
    users = response.response.results;
    return users;
  } else {
    return response;
  }
}

const login = async (body) => {
  const url = '/api/login';
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body),
  })
  .then(function(response) {
    if (response.status !== 200) return ({ success:false, message:'failed login' });
    return response.json()
  });
}

export default {
  getUsers,
  login,
}

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Article from '../components/article'
import { loadArticles, loadSingleArticle } from '../actions/articles'
import { loadSingleAuthor, loadAuthors } from '../actions/users'

const mapStateToProps = state => {
  return {
    ...state,
  }
}

const mapDispatchToProps = dispatch => ({
  loadArticles: bindActionCreators(loadArticles, dispatch),
  loadSingleArticle: bindActionCreators(loadSingleArticle, dispatch),
  loadSingleAuthor: bindActionCreators(loadSingleAuthor, dispatch),
  loadAuthors: bindActionCreators(loadAuthors, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Article)

import React, {useEffect} from 'react';
import { useAuth } from "../../context/auth";

const BannerAd = (props) => {
  const { adKey } = props;
  const { authTokens } = useAuth();
  useEffect(() => {
    if (!authTokens) {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
  }, [adKey, authTokens]);


  return !authTokens ? (
    <div key={adKey}>
      <ins
        className='adsbygoogle'
        style={{ display: 'block' }}
        data-ad-client='ca-pub-1684709374896374'
        data-ad-slot='1866972673'
        data-ad-format='auto'
        data-full-width-responsive="true"
      />
    </div>
  )
  :
  null;

}
export default BannerAd;

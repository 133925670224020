const reducer = (state = {}, action) => {
  switch (action.type) {
    case 'LOAD_CATEGORIES':
      return {
        ...state,
        data: action.data || [],
      }
    default:
      return state
  }
}
export default reducer


import React from 'react'

const TermsOfUse = () => (
  <div>
    <p>Terms of Use Agreement</p>
    <p>PLEASE READ THIS WEBSITE REQUIRES CONSIDERATION FOR AND AS A CONDITION OF ALLOWING YOU ACCESS.</p>
    <p>READING AND ACCEPTING THE TERMS OF USE AGREEMENT AND READING AND ACCEPTING THE PROVISIONS OF THE PRIVACY POLICY OF THIS WEBSITE ARE REQUIRED CONSIDERATIONS FOR THE WEBSITE GRANTING YOU THE RIGHT TO VISIT, READ, RESELL, TRANSACT, PROCESS PAYMENTS FOR OR INTERACT WITH IT IN ANY WAY. BY VISITING THIS WEBSITE YOU ARE ACKNOWLEDGING THAT ALL TERMS OF USE HAVE BEEN TRANSMITTED TO YOU. ANY AND ALL AGREEMENTS, REPRESENTATIONS, PROMISES, WARRANTIES, ACTIONS, OR STATEMENTS BY VISITOR THAT DIFFER IN ANY WAY FROM THIS AGREEMENT SHALL BE GIVEN NO FORCE OR EFFECT.</p>
    <p>ALL PERSONS ARE DENIED ACCESS TO OR USE OF THIS SITE UNLESS THEY READ AND ACCEPT THE TERMS OF USE AND THE PRIVACY POLICY.</p>
    <p>BY VIEWING, VISITING, USING, TRANSACTING PAYMENTS ON BEHALF OF, OR INTERACTING WITH THIS WEBSITE AS A RESELLER, INTERMEDIARY, AFFILIATE, CUSTOMER, MERCHANT SERVICE PROVIDER, PUBLISHER, ADVERTISER OR ANY INTERACTION WHATSOEVER YOU ARE AGREEING TO ALL THE PROVISIONS OF THIS TERMS OF USE POLICY AND THE PRIVACY POLICY OF THIS WEBSITE. INCLUDING ANY CHANGES TO THIS AGREEMENT OR ADDITIONAL POLICIES INCORPORATED BY REFERENCE WHICH WEBSITE MAY MAKE IN ITS SOLE DISCRETION IN THE FUTURE.</p>
    <p>ALL PERSONS UNDER THE AGE OF 18 ARE DENIED ACCESS TO THIS WEBSITE. IF YOU ARE UNDER 18 YEARS OF AGE, IT IS UNLAWFUL FOR YOU TO VISIT, READ, OR INTERACT WITH THIS WEBSITE OR ITS CONTENTS IN ANY MANNER. THIS WEBSITE SPECIFICALLY DENIES ACCESS TO ANY INDIVIDUAL THAT IS COVERED BY THE CHILD ONLINE PRIVACY ACT (COPA) OF 1998.</p>
    <p>THIS WEBSITE RESERVES THE RIGHT TO DENY ACCESS TO ANY PERSON OR VIEWER FOR ANY REASON. UNDER THE TERMS OF THE PRIVACY POLICY, WHICH YOU ACCEPT AS A CONDITION FOR VIEWING, THE WEBSITE IS ALLOWED TO COLLECT AND STORE DATA AND INFORMATION FOR THE PURPOSE OF EXCLUSION AND FOR MANY OTHER USES.</p>
    <p>THE TERMS OF USE AGREEMENT MAY CHANGE FROM TIME TO TIME. VISITORS HAVE AN AFFIRMATIVE DUTY, AS PART OF THE CONSIDERATION FOR PERMISSION TO VIEW THIS WEBSITE, TO KEEP THEMSELVES INFORMED OF CHANGES.</p>
    <p>PARTIES TO THE TERMS OF USE AGREEMENT</p>
    <p>Visitors, viewers, users, subscribers, members, affiliates, resellers or customers, collectively referred to herein as “Visitors”, are parties to this agreement. The website and its predecessor websites owners and/or operators are parties to this agreement, herein referred to as “Website.” Visitors understand and acknowledge that this agreement over-rules and supersecedes any and all Visitors agreements with Website, including but not limited to Visitors own electronic website terms of use, privacy policy or other proposed legally binding agreements located on Visitors website.</p>
    <p>Website hereby rejects all Visitors website electonic agreements including but not limited to Visitors Terms and Conditions. This agreement shall govern all parties. In the event of a dispute with Visitor the Website shall be governed by this agreement and by the applicable default rules and laws which shall be settled in binding arbitration or a court of law at the Websites choice in the jurisdiction of the Websites choice. Any and all agreements, representations, promises, warranties, actions, or statements by Visitors website or other proposed agreement that differ in any way from the terms of this agreement shall be given no force or effect. All visitors including resellers, intermediarys,affiliates, joint venture partners, publishers, advertisers, online marketers, and any and all users that visit or access this website in any way shall be subject to mutual release and any contracts or agreements are not permitted to be terminated for any cause or reason without mutual written agreement and assent of the website.</p>
    <p>USE OF INFORMATION FROM THIS WEBSITE</p>
    <p>Unless you have entered into an express written contract with this website to the contrary, visitors, viewers, subscribers, members, affiliates, or customers have no right to use this information in a commercial or public setting; they have no right to broadcast it, copy it, save it, print it, sell it, or publish any portions of the content of this website. By viewing the contents of this website you agree this condition of viewing and you acknowledge that any unauthorized use is unlawful and may subject you to civil or criminal penalties. Again, Visitor has no rights whatsoever to use the content of, or portions thereof, including its databases, invisible pages, linked pages, underlying code, or other intellectual property the site may contain, for any reason for any use whatsoever. Nothing. Visitor agrees to liquidated damages in the amount of U.S.$100,000 in addition to costs and actual damages for breach of this provision. Visitor warrants that he or she understands that accepting this provision is a condition of viewing and that viewing constitutes acceptance.</p>
    <p>OWNERSHIP OF WEBSITE OR RIGHT TO USE, SELL, PUBLISH CONTENTS OF THIS WEBSITE</p>
    <p>The website and its contents are owned or licensed by the website. Material contained on the website must be presumed to be proprietary and copyrighted. Visitors have no rights whatsoever in the site content. Use of website content for any reason is unlawful unless it is done with express contract or permission of the website.</p>
    <p>HYPERLINKING TO SITE, CO-BRANDING, “FRAMING” AND REFERENCING SITE PROHIBITED</p>
    <p>Unless expressly authorized by website, no one may hyperlink this site, or portions thereof, (including, but not limited to, logotypes, trademarks, branding or copyrighted material) to theirs for any reason. Further, you are not allowed to reference the url (website address) of this website in any commercial or non-commercial media without express permission, nor are you allowed to ‘frame’ the site. You specifically agree to cooperate with the Website to remove or de-activate any such activities and be liable for all damages. You hereby agree to liquidated damages of US$100,000.00 plus costs and actual damages for violating this provision.</p>
    <p>DISCLAIMER FOR CONTENTS OF SITE</p>
    <p>The website disclaims any responsibility for the accuracy of the content of this website. Visitors assume the all risk of viewing, reading, using, or relying upon this information. Unless you have otherwise formed an express contract to the contrary with the website, you have no right to rely on any information contained herein as accurate. The website makes no such warranty.</p>
    <p>Every effort has been made to accurately represent this product and its potential. Even though this industry is one of the few where one can write their own check in terms of earnings, there is no guarantee that you will earn any money using the techniques and ideas in these materials. Examples and testimonials in these materials are not to be interpreted as a promise or guarantee of earnings. Earning potential is entirely dependent on the person using our product, their ideas and techniques. This product is not a business opportunity and only provides advice and training about Internet and search engine optimization. This is a new product and system and as such there is no history of earnings from its use. WE DO NOT TRACK ACTUAL EARNINGS OF USERS OF OUR PRODUCT AS THE SAME WOULD VIOLATE THE USERS TRADE SECRETS AND CONFIDENTIAL OR PROPRIETARY INFORMATION. THE INFORMATION ON THIS SITE IS OUR EXPERIENCE WITH THE PRODUCT. IF YOU WANT TO SHARE YOUR EXPERIENCE LET US KNOW.</p>
    <p>DISCLAIMER FOR HARM CAUSED TO YOUR COMPUTER OR SOFTWARE FROM INTERACTING WITH THIS WEBSITE OR ITS CONTENTS. VISITOR ASSUMES ALL RISK OF VIRUSES, WORMS, OR OTHER CORRUPTING FACTORS.</p>
    <p>The website assumes no responsibility for damage to computers or software of the visitor or any person the visitor subsequently communicates with from corrupting code or data that is inadvertently passed to the visitor’s computer. Again, visitor views and interacts with this site, or banners or pop-ups or advertising displayed thereon, at his own risk.</p>
    <p>DISCLAIMER FOR HARM CAUSED BY DOWNLOADS</p>
    <p>Visitor downloads information from this site at this own risk. Website makes no warranty that downloads are free of corrupting computer codes, including, but not limited to, viruses and worms.</p>
    <p>LIMITATION OF LIABILITY</p>
    <p>By viewing, using, or interacting in any manner with this site, including banners, advertising, or pop-ups, downloads, and as a condition of the website to allow his lawful viewing, Visitor forever waives all right to claims of damage of any and all description based on any causal factor resulting in any possible harm, no matter how heinous or extensive, whether physical or emotional, foreseeable or unforeseeable, whether personal or business in nature.</p>
    <p>INDEMNIFICATION</p>
    <p>Visitor agrees that in the event he causes damage, which the Website is required to pay for, the Visitor, as a condition of viewing, promises to reimburse the Website for all.</p>
    <p>SUBMISSIONS</p>
    <p>Visitor agrees as a condition of viewing, that any communication between Visitor and Website is deemed a submission. All submissions, including portions thereof, graphics contained thereon, or any of the content of the submission, shall become the exclusive property of the Website and may be used, without further permission, for commercial use without additional consideration of any kind. Visitor agrees to only communicate that information to the Website, which it wishes to forever allow the Website to use in any manner as it sees fit. “Submissions” is also a provision of the Privacy Policy.</p>
    <p>NOTICE</p>
    <p>No additional notice of any kind for any reason is due Visitor and Visitor expressly warrants an understanding that the right to notice is waived as a condition for permission to view or interact with the website.</p>
    <p>DISPUTES</p>
    <p>As part of the consideration that the Website requires for viewing, using or interacting with this website, Visitor agrees to use binding arbitration for any claim, dispute, or controversy (“CLAIM”) of any kind (whether in contract, tort or otherwise) arising out of or relating to this purchase, this product, including solicitation issues, privacy issues, and terms of use issues. In the event the Visitor is the prevailing party, the Visitor shall bare the cost of its own attorney fees. Website reserves the right to litigate Vistor in a court of law in the jurisdiction of Website’s choice.</p>
    <p>In no case shall the viewer, visitor, member, subscriber or customer have the right to go to court or have a jury trial. Viewer, visitor, member, subscriber or customer will not have the right to engage in pre-trial discovery except as provided in the rules; you will not have the right to participate as a representative or member of any class of claimants pertaining to any claim subject to arbitration; the arbitrator’s decision will be final and binding with limited rights of appeal.</p>
    <p>The prevailing party shall be reimbursed by the other party for any and all costs associated with the dispute arbitration, including attorney fees, collection fees, investigation fees, travel expenses.</p>
    <p>JURISDICTION AND VENUE</p>
    <p>If any matter concerning this purchase shall be brought before a court of law, pre- or post-arbitration, Viewer, visitor, member, subscriber or customer agrees to that the sole and proper jurisdiction to be the state and city declared in the contact information of the web owner unless otherwise here specified. In the event that litigation is in a federal court, the proper court shall be the federal court of the websites choice.</p>
    <p>Billing model and cancellation/refund policy</p>
    <p>Refunds can be requested by contacting customer support by clicking on the link in the footer of the website unless otherwise stated in the offer.</p>
    <p>APPLICABLE LAW</p>
    <p>Viewer, visitor, member, subscriber or customer agrees that the applicable law to be applied shall, in all cases, be that of the state of the Website listing in our contact information.</p>
    <p>CONTACT INFORMATION</p>
    <p>The operator of this website can be reached at ashenfelderenterprises@gmail.com</p>
  </div>
);

export default TermsOfUse;

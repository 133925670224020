import 'whatwg-fetch';
import qs from 'qs';

const getPosts = async (options) => {
  const { query, isRecent } = options
  let posts = [];
  let params = '';
  let url = '/api/posts';
  if (isRecent) url = '/api/recent/posts';
  if (query) {
    params = qs.stringify(query);
    url = `${url}?${params}`;
  }
  const response = await fetch(url)
  .then(function(response) {
    return response.json()
  }).then(function(json) {
      return json;
  }).catch(function(ex) {
    throw Error(ex)
  });
  if (response.status !== 200) throw Error(response.message);
  if (response.response && response.response.results) {
    posts = response.response.results;
  }
  return posts;
}

const getPost = async (options) => {
  const { _id } = options
  let posts = [];
  let url = `/api/posts/${_id}`;

  const response = await fetch(url)
  .then(function(response) {
    return response.json()
  }).then(function(json) {
      return json;
  }).catch(function(ex) {
    throw Error(ex)
  });
  if (response.status !== 200) throw Error(response.message);
  if (response.response && response.response.results) {
    posts = response.response.results;
  }
  return posts;
}

const setPost = async (options) => {
  const { body, id, method, category } = options
  let url = `/api/posts`;
  if (!!category) url = `${url}/${category}`;
  if (!!id) url = `${url}/${id}`;
  let posts = [];
  const response = await fetch(url, {
    method,
    headers: {
      'Content-Type': 'application/json',
      'token': localStorage.getItem('token')
    },
    body: JSON.stringify(body),
  })
  .then(function(response) {
    return response.json()
  });
  if (response.response && response.response.result) {
    return response.response.result;
  } else {
    return response;
  }
}

const uploadImage = async (options) => {
  const { file, category, _id, key } = options
  const url = `/api/posts/${category}/${_id}/media/upload`;
  const response = await fetch(url, {
    method: 'POST',
    body: file,
    headers: {
      'token': localStorage.getItem('token')
    },
  })
  .then(function(response) {
    return response.json()
  }).then(function(json) {
      return json;
  });
  return response;
}

const deleteImage = async (options) => {
  const { category, _id, key, mediaType = 'image' } = options
  const url = `/api/posts/${category}/${_id}/media/delete/${key}/${mediaType}`;
  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'token': localStorage.getItem('token')
    },
  })
  .then(function(response) {
    return response.json()
  });
  return response;
}

export default {
  setPost,
  getPosts,
  getPost,
  uploadImage,
  deleteImage
}

import React, { useState } from 'react'
import { Provider } from 'react-redux'
import { createStore, compose, applyMiddleware } from 'redux'
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import thunk from 'redux-thunk'
import Ad from '../ad'
import { AuthContext } from "../../context/auth";
import Footer from '../footer'
import Header from '../../containers/header'
import reducer from '../../reducers'
import Router from './router';
import Snackbar from '../../containers/snackbar'
import { Helmet } from 'react-helmet';
import MomentDateUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#428e92',
      main: '#006064',
      dark: '#00363a',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#b2fef7',
      main: '#80cbc4',
      dark: '#4f9a94',
      contrastText: '#000000',
    },
  },
});

// here is our redux-store
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(thunk))
);

const App = () => {

  const existingToken = localStorage.getItem("token");
  const [authTokens, setAuthToken] = useState(existingToken);

  const setToken = (data) => {
    if (data && data.token) {
      localStorage.setItem("token", data.token);
      setAuthToken(data.token);
    } else {
      localStorage.removeItem("token");
      setAuthToken(null);
    }
  }

  return (
    <Provider store={store}>
      <AuthContext.Provider value={{ authTokens, setAuthToken: setToken }}>
        <div>
          <Helmet>
          <title>Blogiply - Live. Experience. Share. Get Paid!</title>
          </Helmet>
          <ThemeProvider theme={theme}>
          <CssBaseline />
          <MuiPickersUtilsProvider utils={MomentDateUtils}>
            <Header />
            <Container maxWidth="lg">
              <main className="m-b-50">
                <Router />
                <Snackbar key={1}/>
              </main>
            </Container>
            <Footer />
          </MuiPickersUtilsProvider>
          </ThemeProvider>
        </div>
      </AuthContext.Provider>
    </Provider>
  );
}

export default App

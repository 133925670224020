import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Hidden from '@material-ui/core/Hidden';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import moment from 'moment';

const useStyles = makeStyles({
  card: {
    display: 'flex',
  },
  cardDetails: {
    flex: 1,
  },
  cardMedia: {
    width: 160,
  },
  media: {
    height: 140,
  },
});

export default function FeaturedPost(props) {
  const classes = useStyles();
  const { post } = props;
  const truncatedTitle = post.title.length > 25 ? post.title.slice(0,25) + '...' : post.title;
  const truncatedDescription = post.description.length > 25 ? post.description.slice(0,25) + '...' : post.description;

  return (
    <Grid item xs={12} lg={6} md={6} sm={6}>
      <Hidden smDown>
        <CardActionArea component="a" href={`/${post.category}/article/${post._id}`}>
          <Card className={classes.card}>
            <div className={classes.cardDetails}>
              <CardContent>
                <Typography component="h2" variant="h5">
                  {truncatedTitle}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  {moment(post.created_at).format('LL')}
                </Typography>
                <Typography variant="subtitle1" paragraph>
                  {truncatedDescription}
                </Typography>
                <Typography variant="subtitle1" color="primary">
                  Continue reading...
                </Typography>
              </CardContent>
            </div>
              <CardMedia className={classes.cardMedia}  title={post.imageTitle}>
                <LazyLoadImage
                  src={post.main_image || '/pin.png'}
                  effect='blur'
                  width='160'
                  height='180'
                />
              </CardMedia>
          </Card>
        </CardActionArea>
      </Hidden>
      <Hidden mdUp>
        <CardActionArea component="a" href={`/${post.category}/article/${post._id}`}>
          <Card className={classes.root}>
            <CardMedia title={post.imageTitle}>
              <LazyLoadImage
                src={post.main_image || '/pin.png'}
                effect='blur'
                width='100%'
                height='auto'
              />
            </CardMedia>
            <CardContent>
              <Typography component="h2" variant="h5">
                {truncatedTitle}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                {moment(post.created_at).format('LL')}
              </Typography>
              <Typography variant="subtitle1" paragraph>
                {truncatedDescription}
              </Typography>
              <Typography variant="subtitle1" color="primary">
                Continue reading...
              </Typography>
           </CardContent>
         </Card>
        </CardActionArea>
      </Hidden>
    </Grid>
  );
}

FeaturedPost.propTypes = {
  post: PropTypes.object,
};

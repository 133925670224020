import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Toolbar from '@material-ui/core/Toolbar';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {`Copyright © Blogiply ${new Date().getFullYear()}.`}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.background.paper,
    // marginTop: theme.spacing(8),
    padding: theme.spacing(3, 0),
  },
  toolbarSecondary: {
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
  },
}));

export default function Footer(props) {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Container maxWidth="lg">
        <Copyright />
        <Toolbar component="nav" variant="dense" className={classes.toolbarSecondary}>
            <Link
              href={'/digital-millennium-copyright-act-notice'}
              className={classes.toolbarLink}
              >
              digital millennium copyright act
            </Link>
            <Link
              href={'/cookie-policy'}
              className={classes.toolbarLink}
              >
              cookie policy
            </Link>
            <Link
              href={'/disclaimer'}
              className={classes.toolbarLink}
              >
              disclaimer
            </Link>
            <Link
              href={'/privacy-policy'}
              className={classes.toolbarLink}
              >
              privacy policy
            </Link>
            <Link
              href={'/terms-of-use'}
              className={classes.toolbarLink}
              >
              terms of use
            </Link>
        </Toolbar>
      </Container>
    </footer>
  );
}

Footer.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
};

const reducer = (state = { init: false }, action) => {
  switch (action.type) {
    case 'LOAD_ARTICLES_INIT':
      return {
        ...state,
        init: true,
      }
    case 'LOAD_ARTICLES':
      return {
        ...state,
        init: true,
        data: action.data || [],
      }
    case 'LOAD_SINGLE_ARTICLE':
      return {
        ...state,
        init: true,
        single: action.data[0] || {}
      }
    default:
      return state
  }
}
export default reducer

const reducer = (state = { userList: [] }, action) => {
  switch (action.type) {
    case 'LOAD_SINGLE_USER':
      return {
        ...state,
        single: action.data[0] || {},
      }
    case 'LOAD_AUTH_USER':
      return {
        ...state,
        authUser: action.data[0] || {},
      }
    case 'LOAD_USERS':
      return {
        ...state,
        userList: action.data || [],
      }
      case 'LOAD_AUTH_ERROR':
        return {
          ...state,
          authUser: {
            error: true,
          }
        }
    default:
      return state
  }
}
export default reducer
